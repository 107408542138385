/* eslint-disable no-console */
import axios from 'axios';
import { ACCESS_TOKEN_ENDPOINT } from '../../constants/endpoints';

let blockRequests = false;

export const fetchAccessToken = async ({ spaceId }) => {
  const URL = ACCESS_TOKEN_ENDPOINT(spaceId);

  if (blockRequests) {
    console.warn('API call blocked:', URL);
    return null;
  }
  const { data } = await axios.get(URL, {
    withCredentials: true,
  });

  return data;
};

export const setAPIBlockAccessToken = (shouldBlock) => {
  blockRequests = shouldBlock;
  console.log(`API Blocking is now ${shouldBlock ? 'ENABLED' : 'DISABLED'}`);
};
